
  import { defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'history-listing',
    props: {
      driverData: { type: Object, default: null },
    },
    components: {
      Datatable,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const router = useRouter();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          name: translate('Shipment ID'),
          key: 'tracknumber',
          sortable: false,
        },
        {
          name: translate('clientName'),
          key: 'clientName',
          sortable: false,
        },
        {
          name: translate('type'),
          key: 'type',
          sortable: false,
        },
        {
          name: translate('createdAt'),
          key: 'createdAt',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'status',
          sortable: false,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);

      const shipments = (id, shipmentType) => {
        if (shipmentType === 'hub') {
          router.push({ name: 'hub-shipment-editing', params: { id } });
        } else {
          router.push({ name: 'shipment-editing', params: { id } });
        }
      };

      return {
        shipments,
        tableHeader,
        translate,
      };
    },
  });
