import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "collapse show" }
const _hoisted_3 = { class: "card-body border-top p-9" }
const _hoisted_4 = { class: "badge-light-info badge" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Datatable, {
          "table-header": _ctx.tableHeader,
          "table-data": _ctx.driverData.Shipments,
          "rows-per-page": 5,
          "enable-items-per-page-dropdown": false
        }, {
          "cell-tracknumber": _withCtx(({ row: history }) => [
            _createTextVNode(_toDisplayString(history.tracknumber), 1)
          ]),
          "cell-clientName": _withCtx(({ row: history }) => [
            _createTextVNode(_toDisplayString(history.clientName), 1)
          ]),
          "cell-type": _withCtx(({ row: history }) => [
            _createTextVNode(_toDisplayString(history.type), 1)
          ]),
          "cell-createdAt": _withCtx(({ row: history }) => [
            _createTextVNode(_toDisplayString(history.createdAt), 1)
          ]),
          "cell-status": _withCtx(({ row: history }) => [
            _createElementVNode("span", _hoisted_4, _toDisplayString(history.status), 1)
          ]),
          "cell-actions": _withCtx(({ row: history }) => [
            _createElementVNode("a", {
              href: "#",
              class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2",
              onClick: _withModifiers(($event: any) => (_ctx.shipments(history.id, history.shipmentType)), ["prevent"])
            }, [
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen021.svg" })
              ])
            ], 8, _hoisted_5)
          ]),
          _: 1
        }, 8, ["table-header", "table-data"])
      ])
    ])
  ]))
}