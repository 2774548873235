import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "fw-bolder" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_7 = { class: "card-footer d-flex justify-content-end py-6 px-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShipmentHistoryListing = _resolveComponent("ShipmentHistoryListing")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.translate('shipments')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("button", {
                  class: "btn btn-light btn-active-light-primary me-2",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
                }, _toDisplayString(_ctx.translate('backToDriversList')), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_ShipmentHistoryListing, { "driver-data": _ctx.driver }, null, 8, ["driver-data"])
  ], 64))
}