
  import { defineComponent, onMounted, ref } from 'vue';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useRoute, useRouter } from 'vue-router';
  import { useAbility } from '@casl/vue';
  import { DriverObject } from '@/store/modules/assets/DriversModule';
  import ShipmentHistoryListing from './ShipmentHistoryListing.vue';

  export default defineComponent({
    name: 'shipment-history',
    components: {
      ShipmentHistoryListing,
    },

    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const route = useRoute();
      const router = useRouter();
      const { can } = useAbility();
      const packages = ref();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const translate = (text, options = {}) => {
        if (te(text)) {
          return t(text, options);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('shipmentsHistory'), [
          translate('assets'),
        ]);
      });

      const getBadgeClass = (val) => {
        let status = 'badge-light-success';
        if (!val) {
          status = 'badge-light-danger';
        }
        return status;
      };

      const { data: driverData } = await store.dispatch(
        Actions.GET_DRIVER,
        route.params.id
      );

      const driver = ref<DriverObject>(driverData.data);

      return {
        goBack,
        packages,
        translate,
        driver,
        can,
        getBadgeClass,
      };
    },
  });
